
import jake from "./image/IMG_5758.png";


function App() {
  const sparkles = Array.from({ length: 20 }).map((_, index) => (
    <div key={index} className="sparkle"></div>
  ));

  return (
    <>
      <div className="headerContainer">
        <svg width="100%" height="500" viewBox="0 0 500 500">
          <defs>
            <path id="textPath" d="M73.2,148.6c4-6.1,65.5-96.8,178.6-95.6c111.3,1.2,170.8,90.3,175.1,97" />
          </defs>
          <text fontSize="39" fill="white">
            <textPath href="#textPath">Currently, Freakin My Shit </textPath>
          </text>
        </svg>
      </div>
      <div className="sparkle-container">
        <img src={jake} alt="its the boy" className="sparkle-image" />
        <div className="sparkles">{sparkles}</div>
      </div>
    </>
  );
}

export default App;
